import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { confirmPasswordReset } from "../../../services/AuthService";
import { useMobileLayout } from "../../../services/UiServices";

import { PasswordField } from "../../../components";

import { ROUTES } from "../../../routes/routes";
import { LOGIN_STRINGS } from "../../../strings";

const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      textAlign: "center",
    },
    form: {
      display: "contents",
    },
    actions: {
      display: "flex",
      justifyContent: "center",
    },
    mobileActions: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    helperText: {
      fontWeight: "normal",
    },
    errorRow: {
      textAlign: "left",
      paddingLeft: theme.spacing(1.5),
      marginTop: theme.spacing(1),
      fontSize: 14,
    },
    button: {
      width: 200,
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
  };
});

const ResetPasswordDialog = ({
  open,
  onClose,
  email,
  oobCode,
  handleError,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const mobile = useMobileLayout();

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const onBlurPassword = () => {
    return isPasswordValid(password)
      ? setPasswordError("")
      : setPasswordError(LOGIN_STRINGS.ERROR_WEAK_PASSWORD);
  };

  const onChangePassword = (value) => {
    setPassword(value);
  };

  const isPasswordValid = (password) => {
    if (password === "") {
      return false;
    }
    if (password.length < 6) {
      return false;
    } else {
      return true;
    }
  };

  const onBlurConfirmPassword = () => {
    return isConfirmPasswordValid()
      ? setConfirmPasswordError("")
      : setConfirmPasswordError(LOGIN_STRINGS.ERROR_CONFIRM_PASSWORD);
  };

  const onChangeConfirmPassword = (value) => {
    setConfirmPassword(value);
  };

  const isConfirmPasswordValid = () => {
    if (confirmPassword !== password) {
      return false;
    } else {
      return true;
    }
  };

  const onResetPassword = async (event) => {
    event.preventDefault();
    try {
      await confirmPasswordReset(oobCode, password);
      enqueueSnackbar(LOGIN_STRINGS.RESET_PASSWORD_CONFIRMATION, {
        variant: "success",
      });
      history.push(ROUTES.login);
    } catch (error) {
      console.log(error);
      handleError();
    }
  };

  const isFormInvalid = () => {
    return (
      password === "" ||
      confirmPassword === "" ||
      !isPasswordValid(password) ||
      !isConfirmPasswordValid(confirmPassword)
    );
  };

  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth="xs"
      open={open}
      fullScreen={mobile}
      onClose={onClose}
    >
      <form className={classes.form}>
        <DialogTitle>{LOGIN_STRINGS.RESET_PASSWORD_TITLE}</DialogTitle>
        <DialogContent className={classes.marginBottom}>
          <Typography
            className={classes.marginBottom}
            variant="body1"
            color="textSecondary"
          >
            {`${LOGIN_STRINGS.RESET_PASSWORD_CONTENT.replace(
              "{email}",
              email
            )}`}
          </Typography>
          <div className={classes.marginBottom}>
            <PasswordField
              error={passwordError}
              name="password"
              label={LOGIN_STRINGS.RESET_PASSWORD_PASSWORD_LABEL}
              onChange={onChangePassword}
              onBlur={onBlurPassword}
            />
          </div>

          <PasswordField
            error={confirmPasswordError}
            name="confirmPassword"
            label={LOGIN_STRINGS.RESET_PASSWORD_CONFIRM_PASSWORD_LABEL}
            onChange={onChangeConfirmPassword}
            onBlur={onBlurConfirmPassword}
          />
        </DialogContent>
        <DialogActions
          disableSpacing={true}
          className={
            mobile
              ? `${classes.mobileActions} ${classes.marginBottom}`
              : `${classes.actions} ${classes.marginBottom}`
          }
        >
          {mobile && (
            <Button
              className={`${classes.button} ${classes.marginBottom}`}
              onClick={onClose}
              variant="contained"
              size="large"
            >
              <Typography variant="button">
                {LOGIN_STRINGS.FORGOT_PASSWORD_CANCEL_BUTTON}
              </Typography>
            </Button>
          )}
          <Button
            type="submit"
            className={classes.button}
            onClick={onResetPassword}
            variant="contained"
            color="primary"
            size="large"
            disabled={isFormInvalid()}
          >
            <Typography variant="button">
              {LOGIN_STRINGS.RESET_PASSWORD_CONFIRM_BUTTON}
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ResetPasswordDialog;
