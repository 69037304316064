import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useMobileLayout } from "../../../services/UiServices";

import { LOGIN_STRINGS } from "../../../strings";

const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      textAlign: "center",
    },
    actions: {
      display: "flex",
      justifyContent: "center",
    },
    mobileActions: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    button: {
      width: 200,
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
  };
});

const ErrorDialog = ({ open, description, title, onConfirm, onClose }) => {
  const classes = useStyles();
  const mobile = useMobileLayout();
  return (
    <Dialog
      open={open}
      fullScreen={mobile}
      className={classes.dialog}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions
        disableSpacing={true}
        className={
          mobile
            ? `${classes.mobileActions} ${classes.marginBottom}`
            : `${classes.actions} ${classes.marginBottom}`
        }
      >
        <Button
          color="primary"
          className={`${classes.button} ${classes.marginBottom}`}
          onClick={onConfirm}
          variant="contained"
          size="large"
        >
          <Typography variant="button">{LOGIN_STRINGS.BUTTON_OK}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
