import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import { isEmailValid } from "../../../utils";
import { LOGIN_STRINGS } from "../../../strings";
import { resetPassword } from "../../../services/AuthService";

const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      textAlign: "center",
    },
    form: {
      display: "contents",
    },
    actions: {
      display: "flex",
      justifyContent: "center",
    },
    mobileActions: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    helperText: {
      fontWeight: "normal",
    },
    errorRow: {
      textAlign: "left",
      paddingLeft: theme.spacing(1.5),
      marginTop: theme.spacing(1),
      fontSize: 14,
    },
    button: {
      width: 200,
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
  };
});

const ForgotPasswordDialog = ({ open, onClose, mobile }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");

  const onChangeEmail = (event) => {
    setError("");
    setEmail(event.target.value);
  };

  const onBlurEmail = () => {
    return isEmailValid(email)
      ? setEmailError("")
      : setEmailError(LOGIN_STRINGS.ERROR_INVALID_EMAIL_FORMAT);
  };

  const isFormInvalid = () => !isEmailValid(email);

  const handleError = (tag, error) => {
    console.error(tag, error);
    const showError =
      error.code in LOGIN_STRINGS.ERROR_FORGOT_PASSWORD
        ? LOGIN_STRINGS.ERROR_FORGOT_PASSWORD[error.code]
        : error.message;
    setError(showError);
  };

  const onResetPassword = async (event) => {
    event.preventDefault();
    try {
      await resetPassword(email);
      setError("");
      enqueueSnackbar(LOGIN_STRINGS.FORGOT_PASSWORD_DONE_TITLE, {
        variant: "success",
      });
      setEmail("");
      onClose();
    } catch (error) {
      handleError("onResetPassword", error);
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth="xs"
      open={open}
      fullScreen={mobile}
      onClose={onClose}
    >
      <form className={classes.form}>
        <DialogTitle>{LOGIN_STRINGS.FORGOT_PASSWORD_TITLE}</DialogTitle>
        <DialogContent className={classes.marginBottom}>
          <Typography
            className={classes.marginBottom}
            variant="body1"
            color="textSecondary"
          >
            {LOGIN_STRINGS.FORGOT_PASSWORD_CONTENT}
          </Typography>
          <TextField
            FormHelperTextProps={{
              classes: { error: classes.helperText },
            }}
            fullWidth
            error={emailError === "" ? false : true}
            helperText={emailError}
            name="email"
            label={LOGIN_STRINGS.FORM_EMAIL}
            type="email"
            value={email}
            onChange={onChangeEmail}
            onBlur={onBlurEmail}
          />
          {error !== "" && (
            <div className={classes.errorRow}>
              <span>{error}</span>
            </div>
          )}
        </DialogContent>
        <DialogActions
          disableSpacing={true}
          className={
            mobile
              ? `${classes.mobileActions} ${classes.marginBottom}`
              : `${classes.actions} ${classes.marginBottom}`
          }
        >
          {mobile && (
            <Button
              className={`${classes.button} ${classes.marginBottom}`}
              onClick={onClose}
              variant="contained"
              size="large"
            >
              <Typography variant="button">
                {LOGIN_STRINGS.FORGOT_PASSWORD_CANCEL_BUTTON}
              </Typography>
            </Button>
          )}
          <Button
            type="submit"
            className={classes.button}
            onClick={onResetPassword}
            variant="contained"
            color="primary"
            size="large"
            disabled={isFormInvalid()}
          >
            <Typography variant="button">
              {LOGIN_STRINGS.FORGOT_PASSWORD_PRIMARY_BUTTON}
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ForgotPasswordDialog;
