import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const Container = styled.div`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.palette.primary.main};
    padding: 1rem;
  `}
`;

const Banner = ({ className, text }) => {
  useTheme();

  return (
    <Container className={className}>
      <Typography>{text}</Typography>
    </Container>
  );
};

export default Banner;
