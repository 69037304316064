import React from "react";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";

const MyCard = styled(Card)`
  width: 100%;
  margin: 1rem;
  height: 22rem;
  height: ${(props) => (props.$mobile ? "auto" : "22rem")};
  flex: 1;
`;

const MyCardActionArea = styled(CardActionArea)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 0.5rem;
`;

const DraftTypeCardText = styled(Typography)`
  text-transform: uppercase;
  letter-spacing: 1.25px;
`;

const CardImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardImage = styled.img`
  width: ${(props) => (props.$mobile ? "50%" : "100%")};

`;

const AppCard = ({ title, url, image }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onClick = () => {
    window.location.href = url;
  };
  return (
    <MyCard $mobile={mobile}>
      <MyCardActionArea onClick={onClick}>
        <CardImageContainer>
          <CardImage $mobile={mobile} src={image} alt=""></CardImage>
        </CardImageContainer>
        <CardContent>
          <DraftTypeCardText variant="subtitle1" color="primary">
            {title}
          </DraftTypeCardText>
        </CardContent>
      </MyCardActionArea>
    </MyCard>
  );
};

export default AppCard;
