import { Firebase } from "../App";
import { getApiBase } from "../utils";

export const TESTING_API_BASE =
  "https://europe-west1-myplayertest.cloudfunctions.net/webapi";
export const LIVE_API_BASE =
  "https://europe-west1-myplayerbase.cloudfunctions.net/webapi";

const WEBAPI = {
  LOCAL: "/webapi",
  TESTING: TESTING_API_BASE,
  LIVE: LIVE_API_BASE,
};

const API_BASE = getApiBase(WEBAPI);

const API_HEADERS = {
  Authorization: "Bearer 1fe-PcX6FRvJXthX6bpTDn5Np/rVZyw",
  Accept: "application/json",
  "Content-Type": "application/json",
};

const API_PATHS = {
  USER_CREATE_PROFILE: "/user/createProfile",
  USER_UPDATE_PROFILE: "/user/updateProfile",
  ORGANISATION_AUTO_JOIN: "/organisations/autoJoin",
};

const postRequest = async ({ path, data = {} }) => {
  // const url = "/webapi" + path;
  const url = API_BASE + path;
  const idToken = await Firebase.auth().currentUser.getIdToken(true);
  const body = {
    idToken,
    ...data,
  };
  return fetch(url, {
    method: "POST",
    headers: API_HEADERS,
    body: JSON.stringify(body),
  }).then((response) =>
    response.ok
      ? response.json()
      : Promise.reject(`Error ${response.status}: ${response.statusText}`)
  );
};

/**
 * Devices
 */

export const createProfile = async (email, name) => {
  const path = API_PATHS.USER_CREATE_PROFILE;
  const data = {
    email,
    name,
  };
  console.debug("createProfile", path, data);
  return postRequest({ path, data });
};

export const updateProfile = async (data) => {
  const path = API_PATHS.USER_UPDATE_PROFILE;
  console.debug("updateProfile", path, data);
  return postRequest({ path, data });
};

export const autoJoinOrganisations = () => {
  const path = API_PATHS.ORGANISATION_AUTO_JOIN;
  console.debug("autoJoinOrganisations", path);
  return postRequest({ path });
};
