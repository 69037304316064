import React from "react";
import styled from "styled-components";

const MyTitle = styled.h1`
  font-size: 22px;
  font-weight: 500;
  margin: 1rem;
`;
const Title = ({ title }) => {
  return <MyTitle>{title}</MyTitle>;
};
export default Title;
