import React, {useState} from "react";
import styled from "styled-components";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";

import {MoreVert} from "@material-ui/icons";

import {GENERAL_STRINGS} from "../strings";

const RightContainer = styled(Box)`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
`;

const MenuItemText = styled.span`
  margin-right: 0.5rem;
`;

// const config = {
//   title: "MyPlayer",
//   overflow: [
//     {
//       label: "Menu Item 1",
//       onClick: () => {},
//     },
//   ],
// };

const Navbar = ({config}) => {
  const [showMoreMenu, setShowMoreMenu] = useState(false);

  const handleMoreClose = () => {
    setShowMoreMenu(false);
  };

  const title = config?.title ?? GENERAL_STRINGS.APP_NAME;
  const overflow = config?.overflow;

  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <Typography variant="h6">{title}</Typography>
        {overflow && (
          <RightContainer>
            <IconButton
              aria-haspopup="true"
              onClick={(e) => setShowMoreMenu(e.currentTarget)}
              color="inherit"
            >
              <MoreVert />
            </IconButton>
            <Menu
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              anchorEl={showMoreMenu}
              keepMounted
              open={Boolean(showMoreMenu)}
              onClose={handleMoreClose}
            >
              {overflow.map((m, i) => (
                <MenuItem key={`${m.label}=${i}`} onClick={m?.onClick}>
                  <MenuItemText>{m?.label}</MenuItemText>
                </MenuItem>
              ))}
            </Menu>
          </RightContainer>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
