export const AUTH_STRINGS = {
  VERIFY_EMAIL_BANNER: "You must verify your email to access this page",
  VERIFY_EMAIL_MESSAGE: "A verification email has been sent to : ",
  VERIFY_EMAIL_SENT: "Verification email sent",
  VERIFY_EMAIL_CHECK_MAILBOX: "Please check your mailbox including spam folder",
  VERIFY_EMAIL_RESEND_COUNTING_1: "You can request for a resend in ",
  VERIFY_EMAIL_RESEND_COUNTING_2: " seconds",
  VERIFY_EMAIL_RESEND_READY_1: "You can request for a ",
  VERIFY_EMAIL_RESEND_READY_2: "resend",
}

export const GENERAL_STRINGS = {
  APP_NAME: "Accounts",

  MSG_ERROR: "An error has occurred!",
}

export const HOME_STRINGS = {
  APP_MYPROJECTS: "MyProjects",
  APP_CREATE: "Create",
  MENU_LOGOUT: "Logout",
}

export const LOGIN_STRINGS = {
  FORM_EMAIL: "Email",
  FORM_PASSWORD_REGISTER: "Choose password",
  FORM_PASSWORD_SIGNIN: "Password",
  FORM_NAME: "Name",
  FORM_REGISTER: "Sign Up",
  FORM_SIGNIN: "Log In",

  ERROR_SIGN_IN: "Invalid details, please try again later.",
  ERROR_SIGN_UP: "Failed to sign up, please try again later.",
  ERROR_WEAK_PASSWORD: "Password should be at least 6 characters.",
  ERROR_CONFIRM_PASSWORD: "Passwords need to match.",
  ERROR_INVALID_EMAIL_FORMAT: "The email address is badly formatted.",
  ERROR_REGISTER_TRANSACTION: {
    "auth/email-already-in-use": "User already registered, please sign in.",
  },
  ERROR_FORGOT_PASSWORD: {
    "auth/user-not-found": "Email not registered, please try again.",
  },

  ACCOUNT_CREATED: "Account Created!",

  LINK_REGISTER: "Don't have an account?",
  LINK_REGISTER_PRIMARY: "Sign up",
  LINK_SIGNIN: "Have an account?",
  LINK_SIGNIN_PRIMARY: "Log In",

  META_TITLE_HOME: "MyPlayer",
  META_DESCRIPTION_HOME: "MyPlayer",
  META_TITLE_PROJECTS: "MyPlayer - Projects",
  META_DESCRIPTION_PROJECTS: "MyPlayer - Projects",
  META_TITLE_PROFILE: "MyPlayer - Profile",
  META_DESCRIPTION_PROFILE: "MyPlayer - Profile",
  META_TITLE_NEWS: "MyPlayer - News",
  META_DESCRIPTION_NEWS: "MyPlayer - News",

  TITLE_SIGNIN: "Log in to {app}",
  TITLE_REGISTER: "Sign up to {app}",
  TITLE_MYPLAYER: "MyPlayer",
  TITLE_MYPROJECTS: "MyProjects",
  TITLE_CREATE: "Create",

  FORGOT_PASSWORD: "Forgot Password?",
  FORGOT_PASSWORD_TITLE: "Trouble Logging in?",
  FORGOT_PASSWORD_CONTENT:
    "Enter your email and we'll send you a link to reset your password.",
  FORGOT_PASSWORD_PRIMARY_BUTTON: "Send Email",
  FORGOT_PASSWORD_CANCEL_BUTTON: "Cancel",
  FORGOT_PASSWORD_PLACEHOLDER: "Enter your email address",
  FORGOT_PASSWORD_DONE_TITLE: "Password Reset Email Sent",
  FORGOT_PASSWORD_DONE_MSG: "Please check your {email} inbox",

  RESET_PASSWORD_TITLE: "New Password",
  RESET_PASSWORD_CONTENT: "Please enter your new password for {email}",
  RESET_PASSWORD_PASSWORD_LABEL: "Password",
  RESET_PASSWORD_CONFIRM_PASSWORD_LABEL: "Confirm Password",
  RESET_PASSWORD_CONFIRM_BUTTON: "Confirm",
  RESET_PASSWORD_CONFIRMATION: "Your Password has now been reset",

  RESET_PASSWORD_ERROR_TITLE: "Password Reset",
  RESET_PASSWORD_ERROR_DESCRIPTION:
    "An error occurred and your password could not be reset, please try again.",

  VERIFY_ACCOUNT_CONFIRMATION: "Your account has been activated",
  VERIFY_ACCOUNT_ERROR_TITLE: "Verify Account",
  VERIFY_ACCOUNT_ERROR_DESCRIPTION:
    "An error occurred and your account could not be verified, please try again.",

  TOP_BANNER_MESSAGE: "You must be signed in to access this page",

  BUTTON_OK: "OK",
  FIREBASE_AUTH_ERROR: "You need to be logged in to access this content",

  SIGN_IN_BANNER: "You must be signed in to access this page",
};
