import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  checkAuthStatus,
  getXssToken,
  ssoLogout,
} from "../services/AuthService";
import { Spinner } from "../components";
import { Redirect, Route } from "react-router-dom";
import { ROUTES } from "./routes";
import { ACCOUNTS_URL } from "../config/config";
import VerifyPage from "../pages/Verify";

// A wrapper for <Route> that redirects to the accounts.myplayer.
// screen if the user is not logged in via firebase

const PrivateRoute = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const mounted = useRef(false);
  const auth = useSelector((state) => state.firebase.auth);
  const userAuthenticatedByFirebase = !auth.isEmpty;

  useEffect(() => {
    mounted.current = true;

    const handleAuth = async () => {
      if (userAuthenticatedByFirebase) {
        const xssToken = await getXssToken();
        if (!mounted.current) return;
        if (!xssToken) {
          await ssoLogout();
          if (!mounted.current) return;
          setIsAuthenticated(false);
          return;
        }
        setIsAuthenticated(true);
      } else {
        try {
          await checkAuthStatus();
        } catch {
          if (!mounted.current) return;
          setIsAuthenticated(false);
        }
      }
    };
    handleAuth();

    return () => {
      mounted.current = false;
    };
  }, [userAuthenticatedByFirebase]);

  if (isAuthenticated === undefined) {
    return <Spinner />;
  }
  if (isAuthenticated === false) {
    const redirectTo = `${ROUTES.login}?continue=${ACCOUNTS_URL}`;
    return <Redirect to={redirectTo} />;
  }
  if (auth?.emailVerified === false) return <VerifyPage />;
  return <Route props>{props.children}</Route>;
};

export default PrivateRoute;
