export const ACCOUNTS_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : process.env.REACT_APP_DEMO_MODE
    ? "https://accounts-test.myplayer.io"
    : "https://accounts.myplayer.io";

export const CREATE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : process.env.REACT_APP_DEMO_MODE
    ? "https://create-test.myplayer.io"
    : "https://create.myplayer.io";

export const MYPROJECTS_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3002"
    : process.env.REACT_APP_DEMO_MODE
    ? "https://app-test.myplayer.io"
    : "https://app.myplayer.io";
