import { useEffect, useState } from "react";
import { Firebase, Auth } from "../App";
import axios from "axios";
import { getCookieByKey } from "../utils";

import { LOGIN_STRINGS } from "../strings";
import { autoJoinOrganisations } from "./ApiService";

export const MODES = {
  VERIFY_EMAIL: "verifyEmail",
  RESET_PASSWORD: "resetPassword",
};

const API_BASE = "/createApi";

// verifies password reset code
// returns a Promise with the user's email
export const verifyPasswordResetCode = (oobCode) =>
  Firebase.auth().verifyPasswordResetCode(oobCode);

export const verifyCode = (code) => Firebase.auth().applyActionCode(code);

// logs user in with email and password
// returns a Promise
export const login = (email, password) =>
  Firebase.auth().signInWithEmailAndPassword(email, password);

const sanitiseRedirectUrl = (url) => {
  if (!url) return url;
  // ideally this list should be retrieved from Firebase auth but for simplicity we copy the whitelist here
  const domain = new URL(url)?.hostname;
  if (
    [
      "localhost",
      "127.0.0.1",
      "myplayerbase.firebaseapp.com",
      "myplayerbase.web.app",
      "app.myplayer.io",
      "app-staging.myplayer.io",
      "accounts.myplayer.io",
      "myplayertest.firebaseapp.com",
      "myplayertest.web.app",
      "app-test.myplayer.io",
      "accounts-test.myplayer.io",
    ].includes(domain)
  )
    return url;
  return null;
};

// creates a new user, updates the user collection with document format found in rrfconfig and sends email verification
// returns a Promise
export const register = async (email, password, fullname, redirectUrl) => {
  await Firebase.auth().createUserWithEmailAndPassword(email, password);
  await sendEmailVerification(sanitiseRedirectUrl(redirectUrl));
  return Firebase.auth().currentUser;
};

export const sendEmailVerification = async (url = null) => {
  return Firebase.auth().currentUser.sendEmailVerification(
    url ? { url } : null
  );
};

// sends a password reset email
// returns a Promise
export const resetPassword = (email) =>
  Firebase.auth().sendPasswordResetEmail(email);

// resets user's password
// return Promise
export const confirmPasswordReset = (oobCode, password) =>
  Firebase.auth().confirmPasswordReset(oobCode, password);

// Verify password reset code and get the email
// return email as String
// undefined = loading
// null = invalid
// String = valid email
export const usePasswordResetEmail = ({ mode, oobCode }) => {
  const [email, setEmail] = useState();

  useEffect(() => {
    if (mode !== MODES.RESET_PASSWORD && !oobCode) return;

    verifyPasswordResetCode(oobCode)
      .then(async (email) => {
        await ssoLogout();
        setEmail(email);
      })
      .catch((error) => {
        console.warn(error);
        setEmail(null);
      });
  }, [mode, oobCode]);

  return email;
};

// Verify account
// return result as boolean
// undefined = loading
// false = invalid
// true = valid
export const useVerifyCode = ({ mode, oobCode }) => {
  const [result, setResult] = useState();

  useEffect(() => {
    if (mode !== MODES.VERIFY_EMAIL && !oobCode) return;
    verifyCode(oobCode)
      .then(async () => {
        autoJoinOrganisations();
        await ssoLogout();
        setResult(true);
      })
      .catch((error) => {
        console.warn(error);
        setResult(false);
      });
  }, [mode, oobCode]);

  return result;
};

export const createSessionCookie = async (idToken) => {
  try {
    const response = await axios.post(`${API_BASE}/sessionLogin`, {
      idToken,
    });
    return response;
  } catch (error) {
    console.log("ERROR", error.response);
    throw error;
  }
};

export const signOut = () => Firebase.auth().signOut();

export const sessionLogout = async () => {
  console.log("sessionLogout");
  try {
    await axios.post(`${API_BASE}/sessionLogout`, {});
  } catch (error) {
    console.log(error);
  }
};

export const getXssToken = async () => {
  console.log("getXssToken");
  const xssToken = getCookieByKey("xss");
  return xssToken;
};

export const ssoLogout = async () => {
  console.log("ssoLogout");
  await sessionLogout();
  await signOut();
};

export const checkAuthStatus = async () => {
  console.log("checkAuthStatus");
  const xssToken = await getXssToken();
  if (!xssToken) {
    ssoLogout();
    throw new Error("No xss token");
  }
  console.log("xssToken", xssToken);
  const response = await axios.post(
    `${API_BASE}/checkAuthStatus`,
    {},
    {
      headers: { Authorization: `Bearer ${xssToken}` },
    }
  );
  if (response.data.status === "success") {
    const customToken = response.data.customToken;
    const user = await Auth.signInWithCustomToken(customToken);
    return user;
  }
};

/**
 * utils
 */

const isFromMyProjects = () =>
  window.location.search.search(/app-?\w*\.myplayer\.io/g) > -1;

const isFromCreate = () =>
  window.location.search.search(/create-?\w*\.myplayer\.io/g) > -1;

export const redirectFromAppName = () =>
  isFromMyProjects()
    ? LOGIN_STRINGS.TITLE_MYPROJECTS
    : isFromCreate()
    ? LOGIN_STRINGS.TITLE_CREATE
    : LOGIN_STRINGS.TITLE_MYPLAYER;

export const useRedirectUrl = () => {
  const [redirectUrl, setRedirectUrl] = useState();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get("continue");
    setRedirectUrl(redirectUrl);
  }, []);

  return redirectUrl;
};
